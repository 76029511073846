import { createChatBotMessage } from 'react-chatbot-kit';
import SelfieOrChat from '../widgets/selfieOrChat/selfieOrChat';
import YourDiagnostic from '../widgets/yourDiagnostic/yourDiagnostic';
import MyDiagnostic from '../components/myDiagnostic/myDiagnostic';
import SkinDecision from '../widgets/skinDecision/skinDecision';
import SpecifyNeeds from '../widgets/specifyNeeds/specifyNeeds';
import YesOrNot from '../widgets/yesOrNot/yesOrNot';
import Result from '../widgets/result/result';
import MultiChoices from '../widgets/multiChoices/multiChoices';
import MyAvatar from '../components/myAvatar/myAvatar';
import VideoIntro from '../widgets/videoIntro/videoIntro';
import Offers from "../widgets/offers/offers";
import {Langage} from "../language";
import {appLanguage} from "../select";
import Choices from '../widgets/choices/choices';

const botName = 'cactus';

const getText = (key) => {
  return Langage[key][appLanguage];
}

const config = {
  botName: botName,
  initialMessages: [createChatBotMessage(getText('welcome'), {
    payload: { uid: -1, next: 0 },
    widget: 'videoIntro'
  })
  ],
  customComponents: {
    botAvatar: (props) => <MyAvatar {...props} />
  },
  disableScrollToBottom: true,
  state: {
    imgSelfie: [],
    skinType: null,
    uid: null,
    analysis: null,
    needs: [
      {
        "key": "REGULATION",
      },
      {
        "key": "RIDES",
      },
      {
        "key": "TACHES",
      },
      {
        "key": "PORES",
      },
      {
        "key": "IMPERFECTIONS",
      },
      {
        "key": "ECLAT",
      },
      {
        "key": "HYDRATATION",
      },
      {
        "key": "SENSIBILITE",
      }
    ],
    myNeeds: [],
    saveWidgetsResponse: [],
    arg: [],
    age: null,
    fullName: null,
    genre: null,
    email: null,
    token: null,
    selfie: null,
    hairType: null
  },
  widgets: [
    {
      widgetName: "videoIntro",
      widgetFunc: (props) => <VideoIntro {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "selfieOrChat",
      widgetFunc: (props) => <SelfieOrChat {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "yourDiagnostic",
      widgetFunc: (props) => <YourDiagnostic {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "myDiagnostic",
      widgetFunc: (props) => <MyDiagnostic {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "skinDecision",
      widgetFunc: (props) => <SkinDecision {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "specifyNeeds",
      widgetFunc: (props) => <SpecifyNeeds {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "yesOrNot",
      widgetFunc: (props) => <YesOrNot {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "results",
      widgetFunc: (props) => <Result {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "multiChoices",
      widgetFunc: (props) => <MultiChoices {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "offers",
      widgetFunc: (props) => <Offers {...props} />,
      mapStateToProps: []
    },
    {
      widgetName: "choices",
      widgetFunc: (props) => <Choices {...props} />,
      mapStateToProps: []
    },
  ]
};

export default config;
