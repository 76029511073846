import {appLanguage, titleNormalized} from "./select";
import {Langage} from "./language";


const getText = (key) => {
    return Langage[key][appLanguage]
}

export const startingQuestions = [
    {
        "uid": 0,
        "question": getText('start'),
        "next": 1
    },
    {
        "uid": 1,
        "question": getText('age'),
        "next": 2
    },
    {
        "uid": 2,
        "question": getText('gender'),
        "next": 4,
        "choices": [
            {"text": getText('woman'), "value": getText('woman'), "next": 4},
            {"text": getText('man'), "value": getText('man'), "next": 4},
            {"text": getText('not_define'), "value": getText('not_define'), "next": 4}
        ],
        "widget": "yesOrNot"
    },
    {
        "uid": 4,
        "question": getText('renseigne_email'),
        "next": (titleNormalized === 'chatbot-hair' ? 45 : 25)
    },
    {
        "uid": 6,
        "question": "Votre peau est elle sensible ou irritée facilement ?",
        "choices": [
            {"text": "Oui", "value": true, "next": 6},
            {"text": "Non", "value": false, "next": 7}
        ],
        "widget": "yesOrNot"
    },
    {
        "uid": 6,
        "question": "Avez-vous des taches rouges sur le visage ?",
        "choices": [
            {"text": "Oui", "value": true, "next": 8},
            {"text": "Non", "value": false, "next": 9}
        ],
        "widget": "yesOrNot"
    },
    {
        "uid": 7,
        "question": "Avez-vous des zones de brillance sur le visage ?",
        "choices": [
            {"text": "Oui", "value": true, "next": 10},
            {"text": "Non", "value": false, "next": 11}
        ],
        "widget": "yesOrNot"
    },
    {
        "uid": 8,
        "question": "Ressentez-vous des tiraillements ou un manque d'hydratation au cours de la journée ?",
        "choices": [
            {"text": "Oui", "value": true, "next": 12},
            {"text": "Non", "value": false, "next": 13}
        ],
        "widget": "yesOrNot"
    },
    {
        "uid": 9,
        "question": "Avez-vous des plaques sèches ou rugueuses sur la peau ?",
        "choices": [
            {"text": "Oui", "value": true, "next": 15},
            {"text": "Non", "value": false, "next": 14}
        ],
        "widget": "yesOrNot"
    },
    {
        "uid": 10,
        "question": "Avez-vous des imperfections ou des boutons ?",
        "choices": [
            {"text": "Oui", "value": true, "next": 17},
            {"text": "Non", "value": false, "next": 18}
        ],
        "widget": "yesOrNot"
    },
    {
        "uid": 11,
        "question": "Avez-vous des rides ou des ridules ?",
        "choices": [
            {"text": "Oui", "value": true, "next": 21 },
            {"text": "Non", "value": false, "next": 22 }
        ],
        "widget": "yesOrNot"
    },
    {
        "uid": 12,
        "final": true,
        "next": 35,
        "value": "SENSIBLE"
    },
    {
        "uid": 13,
        "question": "Avez-vous des plaques sèches ou rugueuses sur la peau ?",
        "choices": [
            {"text": "Oui", "value": true, "next": 15},
            {"text": "Non", "value": false, "next": 12}
        ],
        "widget": "yesOrNot"
    },
    {
        "uid": 14,
        "question": "Ressentez-vous des tiraillements ou un manque d'hydratation au cours de la journée ?",
        "choices": [
            {"text": "Oui", "value": true, "next": 16},
            {"text": "Non", "value": false, "next": 12}
        ],
        "widget": "yesOrNot"
    },
    {
        "uid": 15,
        "final": true,
        "value": "SECHE",
        "next": 35
    },
    {
        "uid": 16,
        "final": true,
        "value": "DESHYDRATEE",
        "next": 35
    },
    {
        "uid": 17,
        "final": true,
        "value": "ACNEIQUE",
        "next": 35
    },
    {
        "uid": 18,
        "question": "Presentez-vous des zones de sécheresse ?",
        "choices": [
            {"text": "Oui", "value": true, "next": 19},
            {"text": "Non", "value": false, "next": 20}
        ],
        "widget": "yesOrNot"
    },
    {
        "uid": 19,
        "final": true,
        "value": "MIXTE",
        "next": 35
    },
    {
        "uid": 20,
        "final": true,
        "value": "GRASSE",
        "next": 35
    },
    {
        "uid": 21,
        "question": "Avez-vous des taches brunes ?",
        "choices": [
            {"text": "Oui", "value": true, "next": 23},
            {"text": "Non", "value": false, "next": 16}
        ],
        "widget": "yesOrNot"
    },
    {
        "uid": 22,
        "question": "Ressentez-vous des tiraillements ou un manque d'hydratation au cours de la journée ?",
        "choices": [
            {"text": "Oui", "value": true, "next": 16},
            {"text": "Non", "value": false, "next": 24}
        ],
        "widget": "yesOrNot"
    },
    {
        "uid": 23,
        "final": true,
        "value": "MATURE",
        "next": 35
    },
    {
        "uid": 24,
        "final": true,
        "value": "EQUILIBREE",
        "next": 35
    },
    {
        "uid": 25,
        "question": getText('determine'),
        "choices": [
            {"text": "Oui", "value": true, "next": 26},
            {"text": "Non", "value": false, "next": 5}
        ],
        "widget": "yourDiagnostic"
    },
    {
        "uid": 26,
        "question": "D'après le selfie, voici votre peau aujourd'hui :",
        "next": 27,
        "widget": "results"

    },
    {
        "uid": 27,
        "question": "Êtes-vous d'accord avec cela ?",
        "choices": [
            {"text": "Oui", "value": true, "next": 33},
            {"text": "Non", "value": false, "next": 28}
        ],
        "widget": "yesOrNot"

    },
    {
        "uid": 28,
        "question": getText('i_will_propose'),
        "widget": "specifyNeeds"
    },
    {
        "uid": 29,
        "question": "Voici les résultats de votre analyse",
        "choices": [
            {"text": "Oui", "value": true, "next": 26},
            {"text": "Non", "value": false, "next": 5}
        ],
        "widget": "yourDiagnostic"
    },
    {
        "uid": 30,
        "question": "Voici quelques questions pour mieux comprendre votre type de peau :",
        "widget": "skinDecision",
        "next": 5
    },
    {
        "uid": 31,
        "question": "Voici votre prescription"
    },
    {
        "uid": 32,
        "question": "Voici quelques questions pour affiner vos besoins :"
    },
    {
        "uid": 33,
        "question": "continue 2em partie"
    },
    {
        "uid": 34,
        "question": "Voici les résultats de votre analyse",
        "widget": "yourDiagnostic"

    },
    {
        "uid": 35,
        "question": "Voici les résultats de votre analyse",
        "choices": [
            {"text": "Oui", "value": true, "next": 26},
            {"text": "Non", "value": false, "next": 36}
        ],
        "widget": "yourDiagnostic"
    },
    {
        "uid": 36,
        "question": "Je choisis mon type de peau",
        "widget": "skinDecision",
        "next": 26
    },
    {
        "uid": 37,
        "question": "Je choisis mon type de peau",
        "widget": "skinDecision",
        "next": 30
    }
]
