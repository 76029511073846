import {appLanguage, titleNormalized} from "./select";
import {LangageHair} from "./language";

const getText = (key) => {
    return LangageHair[key][appLanguage]
}

export const haireQt = [
    {
        "uid": 0,
        "brief": getText('brief_type'),
        "question": getText('hair_type'),
        "choices": [
            {"text": getText('steep'), "img": 'steep'},
            {"text": getText('corrugated'), "img": 'corrugated'},
            {"text": getText('looped'), "img": 'looped'},
            {"text": getText('curly'), "img": 'curly'},
            {"text": getText('frizzy'), "img": 'frizzy'}
        ],
        "widget": "multiChoices",
        "min_len": 5,
        "delay": 6000
    },
    {
        "uid": 1,
        "brief": getText('brief_thick'),
        "question": getText('hair_thickness'),
        "choices": [
            {"text": getText('fins'), "img": "Fin"},
            {"text": getText('normals'), "img": "Naturel"},
            {"text": getText('thick'), "img": "épai"}
        ],
        "widget": "multiChoices",
        "min_len": 4
    },
    {
        "uid": 2,
        "brief": "Longueur cheveux: ",
        "question": getText('length'),
        "choices": [
            {"text": getText('short'), "img": "shorts"},
            {"text": getText('mi_long'), "img": "Mi-longs"},
            {"text": getText('long'), "img": "longs"}
        ],
        "widget": "multiChoices",
        "min_len": 4
    },
   /*  {
        "uid": 3,
        "brief": getText('brief_beauty'),
        "question": getText('goals'),
        "choices": [
            getText('volume'),
            getText('smoothing'),
            getText('loops'),
            getText('shine')
        ],
        "min_len": 15
    }, */
    {
        "uid": 3,
        "brief": getText('brief_color'),
        "question": getText('color'),
        "choices": [
            {"text": getText('blond'), "img": "Blonds"},
            {"text": getText('light_blond'), "img": "Chatains"},
            {"text": getText('brown'), "img": "Bruns"},
            {"text": getText('red'), "img": "Roux"},
            {"text": getText('colorful'), "img": "Colorés"},
            {"text": getText('highlighted'), "img": "Méchés VF"},
            {"text": getText('white_hair'), "img": "Gris _ Blanc "}
            
        ],
        "widget": "choices",
        "min_len": 5
    },
    /* {
        "uid": 4,
        "brief": getText('brief_soin'),
        "question": getText('hair_care'),
        "choices": [
            getText('growth'),
            getText('strengthen'),
            getText('appease'),
            getText('hydrate'),
            getText('feed'),
            getText('repair'),
            getText('sebum')
        ],
        "min_len": 15
    }, */
    {
        "uid": 4,
        "brief": getText('brief_scalp'),
        "question": getText('scalp'),
        "choices": [
            {"text": getText('sec'), "img": 'sec'},
            {"text": getText('normal'), "img": 'scalp_normal'},
            {"text": getText('gras'), "img": 'gras'},
            {"text": getText('irritated'), "img": 'irritated'},
            {"text": getText('none'), "img": 'scalp_none'}
        ],
        "widget": "multiChoices",
        "min_len": 3
    },
    {
        "uid": 5,
        "brief": "",
        "question": getText('dandruff'),
        "choices": [
            {"text": getText('yes'), "value": getText("have_dandruff")},
            {"text": getText('no'), "value": " "},
        ],
        "widget": "multiChoices",
        "min_len": 2
    },
    {
        "uid": 6,
        "brief": "",
        "question": getText('hair_loss'),
        "choices": [
            {"text": getText('yes'), "value": getText("have_hair_loss")},
            {"text": getText('no'), "value": " "},
        ],
        "widget": "multiChoices",
        "min_len": 2
    },
    {
        "uid": 7,
        "brief": getText("brief_expectation"),
        "question": getText('expectation'),
        "choices": [
            {"text": getText("bio"), "img": "Bio"},
            {"text": getText("naturals"), "img": "Produits_naturels"},
            {"text": getText("made_FR"), "img": "Fabrication_française"},
            {"text": getText("others"), "img": "Autre"}
        ],
        "widget": "choices",
        "min_len": 2
    },
    {
        "uid": 8,
        "question": (titleNormalized === 'chatbot-hair' ? getText('take_final_selfie') :  getText('start_experience')),
        "choices": [
            {"text": 'prendre Les selfies', "next": 5},
            {"text": "Démarrer le chat",  "next": 5}
        ],
        "widget": "selfieOrChat"
    },
    {
        "uid": 9,
        "brief": "",
        "question": getText('recommend_products'),
        "choices": [getText('see_selection')],
        "widget": "multiChoices",
        "min_len": 0
    }
]
