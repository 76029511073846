import React, { } from 'react'
import { Button, Box } from '@mui/material';
import './skinDecision.css';
import { saveResWidget } from '../../utils/utils';


const SkinDecision = (props) => {
    const { setState, payload } = props

    const type_skin = ['Sèche', 'Grasse', ' Mixte', 'Déshydratée', 'Equilibrée', 'Mature', 'Sensible', 'Acnéique']
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {type_skin.map((item) => <Button key={item} 
            onClick={() => {saveResWidget(item, setState); props.actionProvider.next(payload.next)}
            }
             variant="outlined">{item}</Button> )
        }
        </Box>
    )
}

export default SkinDecision