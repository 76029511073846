import React, { useEffect } from 'react'
import { Avatar } from '@mui/material'
import './myAvatar.css'
import {titleNormalized} from "../../select";


const MyAvatar = () => {
    return (
        <Avatar
        alt="bot"
        src={"/assets/images/profil-" + titleNormalized + ".png"}
        sx={{ width: 56, height: 56 }}
      />
    )
}

export default MyAvatar
