import { Langage } from "../language";
import { appLanguage, titleNormalized } from "../select";

const getText = (key) => {
    return Langage[key][appLanguage]
  }

export const sortScoreArray = (lst, n) => {

    let arr = []
    Object.keys(lst).forEach((key)=> {
        arr.push(
            {
                key: key.substring(10).toUpperCase(),
                final: lst[key].final
            }
        )
    })
    arr.sort(function(a, b) {
        return a.final - b.final;
      });
      arr.reverse()
      return arr
}

export const replaceDashesAndUnderscores = str => {
    return str.replace(/[-_]/g, ' ');
  }

// recois toutes les reponses de l utilisateur et prepare l objet pour la requete a envoye a l api
export const getRequest = (states) =>{
    const form = states.messages;
    let final = [];
    let tmp = {}
    form.forEach((item, index) => {
      if (item.type === 'bot') {
        if (Object.keys(tmp).length > 0) {
          final.push(tmp)
          tmp = {}
        }
        tmp.question = item.message
        tmp.response = ''
        tmp.brief = item.payload.brief
        tmp.besoin = item.payload.besoin || '_BEFORE'
        tmp.uid = item.payload.id || item.payload.uid
        tmp.isSec = item.payload.isSec
      }
      else {
        tmp.response = tmp.response.concat("", item.message)
      }
  
    })
    final = final.sort((a, b) => a.uid - b.uid);
    final = final.filter(f => f.isSec);
    final = final.map(f => {
      delete f.isSec;
      if (f.brief === null) {
        delete f.brief;
      }
      if (titleNormalized === 'chatbot-hair') {
        delete f.besoin;
      }
      return f;
    });

    let infoUser = [
        {
          "question": getText('age_q'),
          "response": states.age,
          "uid": -3,
          isSec: true
        },
        {
          "question": getText('gender_q'),
          "response": states.genre,
          "uid": -2,
          isSec: true
        }
    ]  

    infoUser.forEach(item => {
        final.push(item);
    });

    /* if (titleNormalized !== 'chatbot-hair') {
        final = [
          {
            "question": getText('today_type'),
            "response": states.skinType,
            "uid": -1,
            isSec: true
          }
        ]
      } */

    const request = {
        session: final.filter(f => f.uid !== 16),
        version: 2
      }

      return request
}

/* export const getRequestForGptHair = (states) => 
{
    let final = [];
    let tmp = {}
    const form = states.messages;
    
    form.forEach((item, index) => {
        if (item.type === 'bot') {
          if (Object.keys(tmp).length > 0) {
            final.push(tmp)
            tmp = {}
          }
          tmp.question = item.message
          tmp.response = ''
          tmp.brief = item.payload.brief || null
          tmp.besoin = item.payload.besoin || '_BEFORE'
          tmp.uid = item.payload.id || item.payload.uid
          tmp.isSec = item.payload.isSec
        }
        else {
          tmp.response = tmp.response.concat("", item.message)
        }
    
      })
      final = final.sort((a, b) => a.uid - b.uid);
      final = final.filter(f => f.isSec);
      final = final.map(f => {
        delete f.isSec;
        if (f.brief === null) {
          delete f.brief;
        }
        if (titleNormalized === 'chatbot-hair') {
          delete f.besoin;
        }
        return f;
      });

    final.age = states.age
    final.gender = states.genre
      console.log(final);


} */

