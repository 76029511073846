import React, { useEffect } from 'react'
import {Dialog, DialogTitle, DialogContent, Button} from '@mui/material';
import './myDiagnostic.css'
import {Langage} from "../../language";
import {appLanguage} from "../../select";


const MyDiagnostic = ({open, onClose, analysis, arg}) => {

    const getText = (key) => {
        return Langage[key][appLanguage]
    }
    //const [best, setBest] = useState([])
   
    // useEffect(() => {
        // if(open) {
            // drawRectable('myCanvas');
    //         let video =  document.getElementById('vid');
    //         video.src = 'assets/images/video-3.mp4';
    //         video.play();
    //         setTimeout(() => {
    //             video.src = 'assets/images/video-hold.mp4';
    //             video.play();
    //         }, 4200)
    //     }
    // }, [open]);

    // useEffect(() => {
    //     let tmp = sortScoreArray(analysis.scores, 3)
    //     setBest(tmp)
    // }, [analysis]);


    const drawRectable = (canvasName) => {
        setTimeout(() => {
            
            const canvas = document.getElementById(canvasName);
            if ((canvas).getContext) {
                const ctx = (canvas).getContext('2d');
                const numberOfSides = Object.keys(analysis.scores).length;
                const size = 178;
                const Xcenter = 164.5;
                const Ycenter = 164.5;
                ctx.beginPath();
                Object.keys(analysis.scores).forEach((key, i) => {
                    ctx.lineTo(
                        Xcenter + analysis.scores[key].final * size * Math.cos((i - 0.5) * 2 * Math.PI / numberOfSides),
                        Ycenter + analysis.scores[key].final * size * Math.sin((i - 0.5) * 2 * Math.PI / numberOfSides)
                    );
                })
                ctx.fillStyle = 'rgba(229, 197, 186, 0.5)';
                ctx.fill();
            }
        }, 1000);
    }
    const handleClose = () => {
        onClose();
      };

    const labels = {
        traitementRides: {right: 'Peau mature', left: 'Visage Lisse', label: 'Rides'},
        traitementEclat: {right: 'Teint éclatant', left: 'Teint terne', label: 'Éclat'},
        traitementHydratation: {right: 'Peau déshydratée', left: 'Peau tonique', label: 'Hydratation'},
        traitementImperfections: {right: 'Imperfections présentes', left: 'Peau nette', label: 'Imperfections'},
        traitementPores: {right: 'Grain de peau dilatés', left: 'Grain de peau uniforme', label: 'Grain de peau'},
        traitementRegulation: {right: 'Excès de sébum', left: 'Microbiote Equilibré', label: 'Brillance'},
        traitementSensibilite: {right: 'Peau hyper réactive', left: 'Bon équilibre', label: 'Sensibilité'},
        traitementTaches: {right: 'Imperfections présentes', left: 'Peau nette', label: 'Taches'}
    }

    return (
        <Dialog  open={open} onClose={handleClose} className="analysis-dialog">
            <DialogTitle>{getText('here_your_analysis')}</DialogTitle>
            <DialogContent className="analysis-dialog-content">
                <div className='scores'>
                    {
                        Object.keys(analysis.scores).sort((a,b) => (analysis.scores[b].final * 100) - (analysis.scores[a].final * 100)).map((key, index) => {
                             return <div key={index} className={`score`}>
                                 <span className={`score-label`}>{labels[key].label}</span>
                                 <div className={`score-bars`}>
                                     <div className={'score-text'}>
                                         <div>{labels[key].right}</div>
                                         <div></div>
                                         <div>{labels[key].left}</div>
                                     </div>
                                     <div className={'bars'}>
                                         <div className={'bar'}></div>
                                         <div className={'bar'}></div>
                                         <div className={'bar'}></div>
                                     </div>
                                     <img src={'assets/images/indicator.png'} className={'indicator'} style={{'top': `-${(analysis.scores[key].final * 100)-5}%`}}/>
                                 </div>
                             </div>
                         })
                    }
                </div>
                <Button onClick={ () => { handleClose() } } variant="contained" style={ {'margin': '25px auto', 'display': 'block'}}>
                    {getText('close')}
                </Button>
                {/*{arg.map((item) => <p key={item}>{item}</p> )}*/}
                {/*<h3>Résultat de votre scan de peau:</h3>*/}
              
                {/*<div className='canvasContain'>*/}
                {/*{*/}
                {/*    Object.keys(analysis.scores).map((key, index) => */}
                {/*     {return <span key={index}*/}
                {/*     className={`label label${index}`}>*/}
                {/*    {key.split('traitement')[1]}*/}
                {/*    </span>*/}
                {/*    }*/}
                {/*    )*/}
                {/*}    */}
               
                        {/*<img src="/assets/images/araignee.png" alt=""></img>*/}
                        {/*<canvas height="329" id="myCanvas" width="329"></canvas>*/}
                {/*</div>*/}
            </DialogContent>
        </Dialog>
    )
}

export default MyDiagnostic
