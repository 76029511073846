import React, { useEffect, useState } from 'react';
import { Button, Box } from '@mui/material';
import './multiChoices.css';
import { saveResWidget } from '../../utils/utils';

const MultiChoices = (props) => {
  const { setState, payload, actionProvider } = props;
  const { choices, id } = payload;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 2100); // 2000 millisecondes (2 secondes)

    return () => clearTimeout(timeout);
  }, []); // Le tableau vide [] garantit que cet effet ne se déclenche qu'une fois après le montage initial

  return (
    isVisible && (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {choices.map((item, index) => (
          <Button
            key={index}
            variant="outlined"
            onClick={() => {
              saveResWidget(item.value || item.text || item, setState);
              actionProvider.next(item.next || id + 1);
            }}
          >
            {item.img && <img src={`/assets/images/hair_icons/${item.img}.png`} width={'42'} alt={item.text || item} />}
            {item.text || item}
          </Button>
        ))}
      </Box>
    )
  );
};

export default MultiChoices;
